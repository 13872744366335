<template>
  <ContentWrapper>
    <template v-slot:title>Edit Game</template>
    <Container>
      <Form @submit="editGame">
        <div class="form-wrapper">
          <!-- Game Type -->
          <Input
            id="game type"
            type="text"
            placeholder="Game Type"
            label="Game Type"
            class="bottom-spacing span-width"
            rules="required|max:50"
            :value="game.type"
            :disabled="true"
          />

          <!-- Game Name -->
          <Input
            id="game name"
            type="text"
            placeholder="Game Name"
            :label="gameNameLabel"
            class="bottom-spacing span-width"
            rules="required|max:50"
            maxLength="50"
            @change="updateData($event, 'name')"
            :value="game.name"
          />

          <!-- Game Picture -->
          <p class="title">Game Picture</p>
          <div class="upload-row">
            <input
              type="file"
              accept="image/*"
              id="gamePicture"
              @change="uploadAttachments"
              hidden
            />
            <label for="gamePicture" class="uploadBtn">Upload</label>
            <p>{{ attachmentName }}</p>
          </div>

          <!-- Exchange -->
          <DropdownSelect
            id="exchange"
            label="Exchange*"
            :options="this.returnExchangeObject"
            class="bottom-spacing span-width"
            @change="updateDropdownData($event, 'exchange')"
            rules="required"
            :selected="exchangeProp"
          />

          <!-- Product -->
          <DropdownSelect
            id="product"
            label="Product*"
            :options="this.returnProductObject"
            class="bottom-spacing span-width"
            @change="updateDropdownData($event, 'product')"
            rules="required"
            :selected="productProp"
          />

          <!-- Market Data -->
          <DropdownSelect
            id="market data"
            label="Market Data*"
            :options="this.marketDataList"
            class="bottom-spacing span-width"
            @change="updateData($event, 'market_data_id')"
            rules="required"
            :selected="marketDataProp"
          />

          <!-- Preview Duration -->
          <Input
            id="preview duration"
            type="text"
            placeholder="Preview Duration"
            label="Preview Duration* (Days)"
            class="bottom-spacing span-width"
            rules="required|numeric"
            @change="updateData($event, 'preview_duration')"
            :value="game.preview_duration"
          />

          <!-- Initial Cash Balance -->
          <Input
            id="initial cash balance"
            type="text"
            placeholder="Initial Cash Balance"
            label="Initial Cash Balance*"
            class="bottom-spacing span-width"
            rules="required|numeric"
            @change="updateData($event, 'initial_cash')"
            :value="parseInt(game.initial_cash)"
          />

          <!-- Max Position Limit -->
          <Input
            id="max position"
            type="numeric"
            placeholder="No of positions"
            label="Max Net Position*"
            class="bottom-spacing span-width"
            rules="required|numeric"
            @change="updateData($event, 'max_position')"
            :value="game.max_position"
          />

          <!-- Currency -->
          <Input
            id="currency"
            type="text"
            placeholder="Currency"
            label="Currency*"
            class="bottom-spacing span-width"
            rules="required"
            @change="updateData($event, 'currency')"
            :value="game.currency"
          />

          <!-- Duration -->
          <Input
            id="duration"
            type="text"
            placeholder="No. of days"
            label="Duration (Days)*"
            class="bottom-spacing span-width"
            rules="required|numeric"
            @change="updateData($event, 'duration')"
            :value="game.duration"
          />

          <!-- Max Players -->
          <Input
            id="max players"
            type="text"
            placeholder="No Of Players"
            label="Maximum No. of Players* (Max 100)"
            class="bottom-spacing span-width"
            maxLength="3"
            rules="required|numeric|max_value:100"
            @change="updateData($event, 'max_players')"
            :value="game.max_players"
          />

          <!-- Timer -->
          <Input
            id="timer"
            type="text"
            placeholder="No. of Seconds"
            label="Timer* (Second)"
            class="bottom-spacing span-width"
            rules="required|numeric"
            @change="updateData($event, 'timer')"
            :value="game.timer"
          />

          <Message :class="{ error: this.error }">{{ this.error }}</Message>
          <div class="btn-row flex">
            <SubmitButton :spacerRight="true">Edit</SubmitButton>
            <SubmitButton
              :isDarkTheme="true"
              @onButtonClick="goBack"
              type="button"
              >Cancel</SubmitButton
            >
          </div>
        </div>
      </Form>
    </Container>
  </ContentWrapper>
</template>

<script>
import Form from "@/components/Form";
import Input from "@/components/Input";
import SubmitButton from "@/components/SubmitButton";
import DropdownSelect from "@/components/DropdownSelect";
import Message from "@/components/Message";

import { eventBus } from "@/main.js";

//API
import { gameUpdate, gameShow } from "../../../../../services/adminAPI/game";
import { attachmentStore } from "../../../../../services/api/attachment";
import { productIndex } from "../../../../../services/api/product";
import { exchangeIndex } from "../../../../../services/api/exchange";
import { marketDataIndex } from "../../../../../services/adminAPI/marketData";

export default {
  components: {
    Form,
    Input,
    SubmitButton,
    DropdownSelect,
    Message,
  },
  async created() {
    eventBus.$emit("loader", true);

    try {
      const gameShowAPI = await gameShow(this.$route.params.id);

      const exchangeIndexAPI = await exchangeIndex();
      this.exchangeList = exchangeIndexAPI.data.response.exchanges;

      const productIndexAPI = await productIndex(
        gameShowAPI.data.response.game.market_data.exchange
      );
      this.productList = productIndexAPI.data.response.products;

      const marketDataIndexAPI = await marketDataIndex();
      this.marketDataList = marketDataIndexAPI.data.response.marketData;

      await this.assignValueFromAPI(gameShowAPI.data.response.game);
    } catch (error) {
      console.log("error");
      console.log(error);
      // this.$swal({
      //   icon: 'error',
      //   title: 'Oops',
      //   text: error.response.data.userMessage
      // })
      eventBus.$emit("loader", false);
    }

    eventBus.$emit("loader", false);
  },
  data() {
    return {
      gameNameLabel: "Game Name* (0/50)",
      attachmentName: "",
      marketDataList: [],
      productList: [],
      exchangeList: [],
      error: "",
      exchangeProp: undefined,
      productProp: undefined,
      marketDataProp: undefined,
      game: {
        name: "",
        type: "",
        initial_cash: undefined,
        duration: undefined,
        timer: undefined,
        currency: "",
        max_position: undefined,
        product: undefined,
        exchange: undefined,
        preview_duration: undefined,
        picture: undefined,
        max_players: undefined,
        market_data_id: undefined,
      },
    };
  },
  methods: {
    updateData(data, attr) {
      this.game[attr] = data;
    },
    async updateDropdownData(data, attr) {
      if (attr == "exchange") {
        this.game[attr] = this.exchangeList[data];
        const productIndexAPI = await productIndex(this.game.exchange);

        this.productList = productIndexAPI.data.response.products;
      } else {
        this.game[attr] = this.productList[data];
      }
    },
    async editGame() {
      this.error = "";

      //Remove Game Type from object
      delete this.game.type;

      if (this.game.picture == undefined) {
        delete this.game.picture;
      }

      try {
        await gameUpdate(this.$route.params.id, this.game);
        await this.$store.dispatch("callGameList");

        this.$swal({
          icon: "success",
          title: "Game Updated",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        }).then(() => {
          if (this.userRole === "admin") {
            this.$router.push({ name: "AdminHome" });
          } else {
            this.$router.push({ name: "Home" });
          }
        });
      } catch (error) {
        this.error = error.response.data.userMessage;
      }
    },
    async uploadAttachments(event) {
      const file = event.target.files;
      const filename = file[0].name;
      const fileType = file[0].type;

      //Check file validity
      if (filename.lastIndexOf(".") <= 0 || !fileType.includes("image")) {
        return this.$swal({
          title: "Invalid Attachment",
          text: "File Type Not Allowed",
          icon: "error",
        });
      } else if (filename.split(".").length > 2) {
        return this.$swal({
          title: "Invalid Attachment",
          text: "File with multiple extensions are not allowed",
          icon: "error",
        });
      }

      const data = new FormData();
      data.append("attachment", file[0]);

      //Upload Attachment
      try {
        const response = await attachmentStore(data);
        this.attachmentName = filename;
        this.game.picture = response.data.response.filename;
      } catch (error) {
        return this.$swal({
          title: "Oops",
          icon: "error",
          text: error.response.data.userMessage,
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    async assignValueFromAPI(data) {
      for (let [key, value] of Object.entries(data)) {
        if (key in this.game) {
          this.game[key] = value;
        }
      }

      this.exchangeProp = this.exchangeList.indexOf(data.market_data.exchange);
      this.productProp = this.productList.indexOf(data.market_data.product);
      this.marketDataProp = data.market_data.id;

      this.game.exchange = data.market_data.exchange;
      this.game.product = data.market_data.product;
      this.game.market_data_id = data.market_data.id;
    },
  },
  computed: {
    returnExchangeObject() {
      return this.exchangeList.map(function(data, index) {
        return {
          id: index,
          name: data,
        };
      });
    },
    returnProductObject() {
      return this.productList.map(function(data, index) {
        return {
          id: index,
          name: data,
        };
      });
    },
    gameNameLength() {
      return this.game.name.length;
    },
  },
  watch: {
    gameNameLength(data) {
      this.gameNameLabel = `Game Name* (${data}/50)`;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-container {
  min-height: calc(100vh - 60px);

  @media screen and (max-width: 450px) {
    height: calc(100vh - 60px);
  }
}

.form-wrapper {
  max-width: 600px;
  @media screen and (max-width: 450px) {
    margin-bottom: 1rem;
  }
}

p {
  font-size: 18px;
}

.error {
  margin-bottom: 2rem;
  text-align: left;
}

/deep/ .input-container label,
.title {
  font-size: 18px;

  @media screen and (max-width: 450px) {
    font-size: 15px;
  }
}

.upload-row {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 3px;
}

.uploadBtn {
  padding: 10px 16px;
  background-color: var(--gray414241);
  color: white;
  display: table;
  margin-right: 1rem;
  border-radius: 5px;
  cursor: pointer;
}
</style>
